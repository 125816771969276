body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.control {
  padding: 0px 0px 0px 0px; /* Top Right Bottom Left */
}

.header1 {
  padding-left: 20px;
}

#fieldset {
  border: 1px solid silver;
  padding: 8px;
  border-radius: 4px;
  box-sizing: content-box;
  /*width: 350px;*/
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

legend {
  padding: 2px;
}

#fieldset2 {
  box-sizing: content-box;
  /*width: 350px;*/
  height: 100px;
  padding-bottom: 20px;
  padding-top: 0px;
  margin-top: 10px;
  padding-left: 20px;
}

.clickable {
  cursor: pointer;
}

.emphasized {
  font-style: italic;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.video-js {
  margin-top: 10px;
  margin-left: 20px;
}

.fit-picture {
  width: 500px;
}

.btnWidth {
  width: 70px !important;
}

.col-sm-2 {  /* Tring to get my columns to behave */
  height: 45px;
}

.col-sm-auto {  /* Tring to get my columns to behave */
  height: 45px;
}

/* fieldset {
  margin: 0px;
  border: 1px solid silver;
  padding: 8px;
  border-radius: 4px;
}

legend {
  padding: 2px;
}

#fieldset2 {
  box-sizing: content-box;
  width: 350px;
  height: 100px;
  padding-bottom: 20px;
  padding-top: 0px;
  margin-top: 10px;
  padding-left: 20px;
} */
